<template>
  <div class="video_card h-100 d-flex flex-column">
    <div class="video_card__head">
      <div class="video_header_layout">
      </div>
      <video
          class="w-100 h-100"
          v-if="videonugget.media"
          :src="videonugget.media.url"
          ref="videoPlayer"
          controls
          :poster="videonugget.poster ? videonugget.poster.url : require('@/assets/images/svg/email.svg')"
          preload="auto"
          loop muted>
        <source type="video/mp4">
      </video>
      <div class="image_header" v-else>
        <img src="@/assets/images/svg/email.svg" class="w-100"/>
      </div>
    </div>
    <div class="video_card__body h-100 d-flex flex-column  pl-1 pr-1">
      <div class="video_card__body__title">
        <div class="title_icon">   <feather-icon icon="YoutubeIcon" size="20"/> </div>

        <p class="video_title mb-0 ml-2"> {{ videonugget.name }}</p>
      </div>
      <div class="video_card__body__date">
        <p class="date_txt mb-0 pr-2">{{ $t("Release Date") }}:</p> <span
          class="date_badge"> {{ formatter(videonugget.startdate) }}</span>
      </div>
      <div class="video_card__body__description h-100">
        {{ videonugget.description }}
      </div>
    </div>
    <div class="video_card__footer pl-1 pr-1">
      <div class="event_actions">
        <b-button-group class="w-100">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success" :to="{
                    name: 'apps-videosnippets-edit',
                    params: { id: videonugget.id },
                  }" class="w-50">
            {{ $t("Edit") }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              v-on:click="deleteItem(videonugget.id)"
              class="w-50"
          >
            {{ $t("Delete") }}
          </b-button>
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BButton, BButtonGroup, BEmbed} from "bootstrap-vue";
import moment from 'moment'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  name: "VideoNuggetsCard",
  props: ['videonugget'],
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BButtonGroup,
    BEmbed,
    BCardActions,
  },
  methods: {
    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY')
    },
    deleteItem(videoId) {

      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/videosnippets/${videoId}`)
              .then(response => {
                this.$emit('updateList')
                this.$bvToast.toast(this.$i18n.t('Item deleted'), {
                  title: this.$i18n.t('Success'),
                  variant: 'success',
                  toaster: 'b-toaster-bottom-right'
                })

                this.$emit('updateList')
              })
              .catch(error => {
                console.debug(error)
                this.$bvToast.toast(this.$i18n.t('Item not deleted'), {
                  title: this.$i18n.t('Error'),
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right'
                })
              })
        }
      })
    },
  }


}
</script>

<style scoped lang="scss">

.video_card {
  background: #E5E5E5;
  border-radius: 5px;
  padding-bottom: 10px;

  &__head {
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    position: relative;
    flex-shrink: 0;

    .card_header_layout {
      background: linear-gradient(176.24deg, rgba(255, 255, 255, 0) 7.6%, rgba(64, 64, 64, 0.68) 67.53%, rgba(0, 0, 0, 0.8) 87.47%);
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  &__body {
    &__title {
      margin-top: 10px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      background: #F2F2F2;
      border-radius: 6px;

      .video_title {
        overflow-wrap: anywhere;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #000000;
        opacity: 0.6;
      }
    }

    &__date {
      margin-top: 10px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      background: #F2F2F2;
      border-radius: 6px;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;

      .date_txt {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
        color: #545454;
        opacity: 0.5;
      }

      .date_badge {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #82868B;
        background: rgba(164, 126, 78, 0.15);
        border-radius: 17px;
        padding: 0 10px;
      }


    }

    &__description {
      margin-top: 10px;
      padding: 25px 20px 30px 20px;
      background: #F2F2F2;
      border-radius: 6px;
      color: #727272;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      letter-spacing: 0.4px;
      align-items: baseline;
      position: relative;

      overflow-wrap: anywhere;


      &:after {
        content: '';
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 46.46%, #D9D9D9 93.36%);
        position: absolute;
        width: 100%;
        height: 70px;
        left: 0;
        bottom: 0;
      }

    }
  }

  .event_actions {
    .btn {
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

</style>
