<template>
  <div class="mb-2">
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        :title="$t('Search for Videonuggets')"
        @refresh="getVideoNuggets"
        @create="hRedirect('apps-videosnippets-new')"
        :loaded.sync="loaded"
    >
      <b-card class="mb-4">
        <div>
          <b-row>
            <b-col md="5">
              <validation-provider #default="validationContext" :name="$t('Search')" rules="min:3">
                <b-form-group :label="$t('Name of the Videonugget')">
                  <b-form-input v-model="search" class="d-inline-block mr-1" :placeholder="$t('Search...')"
                                :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

        </div>
      </b-card>

      <b-row v-if="VIDEO_NUGGETS">
        <b-col md="6" xl="3" sm="6" v-for="videonugget in VIDEO_NUGGETS" :key="videonugget.id" class="mb-2">
          <video-nuggets-card :videonugget="videonugget" v-on:updateList="getVideoNuggets()"/>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-1" v-if="!loaded || searchRequest">
        <b-spinner label="Loading..."/>
      </div>
      <b-row v-if="VIDEO_NUGGETS && VIDEO_NUGGETS.length == 0 && loaded">
        <b-col sm="6" md="6" xl="3" class="mb-2">
          <div class="image_header">
            <img src="@/assets/images/svg/email.svg"/>
          </div>
          <b-card>
            <b-card-body class="expert-events-card-body ">
              <h3 class="h3 card-txt text-center py-3 px-2">
                {{ $t('Create your first Video Nugget by clicking the Button below') }}
              </h3>
            </b-card-body>
            <b-card-footer class="justify-content-center d-flex">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm"
                        :to="{ name: 'apps-videosnippets-new' }"
              >
                {{ $t('create your first Video Nugget') }}
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>

      </b-row>

      <div class="d-flex justify-content-center">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm" @click="loadMore"
                  :class="[(VIDEO_NUGGETS_PAGINATION.current_page == VIDEO_NUGGETS_PAGINATION.last_page)?'d-none':'']"
        >
          <b-spinner small v-if="loadMoreData"/>
          {{ $t('Load more entries') }}
        </b-button>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import {mapActions, mapGetters} from 'vuex'
import Ripple from 'vue-ripple-directive'
import VideoNuggetsCard from '@/views/videosnippets/VideoNuggetsCard'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {min, required} from '@validations'
import {BCardActions} from '@core/components/b-card-actions'

export default {

  components: {
    VideoNuggetsCard,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormRating,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    required,
    min,

  },
  filters: {
    moment: function (date) {
      return this.formateDateIfGerman(date)
    }
  },
  data() {
    return {
      loaded: false,
      search: '',
      loading: false,
      page: 1,
      loadMoreData: false,
      searchRequest: false
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    ...mapActions(['GET_VIDEO_NUGGETS', 'RESET_VIDEO_NUGGETS']),
    loadMore() {
      this.loadMoreData = true
      this.getVideoNuggets(this.page + 1)
    },
    async getVideoNuggets(page = 1) {
      this.RESET_VIDEO_NUGGETS()

      this.loaded = false

      let searchParams = {
        search: this.search,
      }
      this.page = page
      await this.GET_VIDEO_NUGGETS({
        page,
        searchParams
      }).then(() => {
        this.loaded = true
        this.loadMoreData = false
        this.searchRequest = false
      })
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null
    },

  }
  ,
  mounted() {
    this.getVideoNuggets()
  },
  computed: {
    ...mapGetters(['VIDEO_NUGGETS', 'VIDEO_NUGGETS_PAGINATION']),
  },
  watch: {
    // whenever question changes, this function will run
    search(newSearchQuery, oldSearchQuery) {
      if ((newSearchQuery != oldSearchQuery) && !this.searchRequest && newSearchQuery.length >= 3) {
        this.searchRequest = true
        this.getVideoNuggets()
      } else {
        if (!newSearchQuery.length) {
          this.searchRequest = true
          this.getVideoNuggets()
        }
      }
    },
  }

}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.video-nuggets-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 220px;
}

.video-nuggets {
  max-height: 200px;
  min-height: 200px;
}

.slogan-videonuggets {
  min-height: 120px;
}

.video-nuggets-description {
  width: 100%;

  p {
    text-align: center;
    width: 100%;
  }
}

.image_header {
  background-color: #F1F0FE;
}
</style>
